import React, { useContext, useState, useEffect } from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Modal,
  Alert,
  Button,
  AlertTitle,
  Slide,
} from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from '@apollo/react-hooks';
import DWT from '../../utils/dynamsoft/dynamsoftSDK';
import { AuthContextTypes, Context as AuthContext } from '../../context/authContext';
import { Context as RTCContext } from '../../context/RTCContext';
import { Context as StatusContext } from '../../context/statusContext';
import axios from 'axios';
import GeneralSnackbar from '../../components/SnackBar';
import { GET_USER_BY_FOLDER } from '../../utils/gql/gqlUsers';
import {
  MasterTableProps,
  SelectedFolder,
  User,
} from '../../components/masterTable/masterTableTypes';
import { StatusContextTypes } from '../../context/statusContextTypes';
import { RTCContextTypes } from '../../context/RTCContextTypes';
import { db } from '../../utils/indexedDB/indexeddb';
const alertStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  justifyContent: 'space-between',
  '& .MuiAlert-action': {
    m: 0,
    p: 0,
  },
  '& .MuiAlert-message': {
    textAlign: 'center',
  },
};

const classes = {
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: 'theme.spacing(2)',
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  selectedFolder: SelectedFolder | null;
  createRecord: MasterTableProps['createRecord'];
  setUnindexed: MasterTableProps['setRows'];
  unIndexed: MasterTableProps['rows'];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  theUser: User[];
  users: User[];
  addTask: any;
  deleteRecords: MasterTableProps['onDeleteButton'];
}

const ScanningModal = (props: Props) => {
  const {
    selectedFolder,
    createRecord,
    setUnindexed,
    unIndexed,
    open,
    setOpen,
    theUser,
    addTask,
    deleteRecords,
    users,
  } = props;
  const { state, setSnackbar } = useContext(StatusContext) as StatusContextTypes;
  const { snackbar } = state;
  const { scannerKey } = useContext(AuthContext) as AuthContextTypes;
  const [errorFiles, setErrorFiles] = useState<any>([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [anchorUploadEl, setAnchorUploadEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const localSt = JSON.parse(localStorage.getItem('userData'));
  const productKey = localSt ? localSt.scan : scannerKey;
  /*   const productKey =
    'f0068lQAAAEmvgQFldx0ZLXFvrf30YkgmQ/hqWgAryr10pcloeVsP1Za36F/g8QdELNf4LFT4/PIdVy87J6XlQnKrAHkrCIs='; */
  const rtcContext = useContext(RTCContext) as RTCContextTypes;
  // const { data } = useQuery(GET_USER_BY_FOLDER, {
  //   variables: {
  //     folderId: selectedFolder ? selectedFolder.id : null,
  //   },
  // });
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorFiles([]);
    setAnchorUploadEl(null);
    if (loadingFiles) setLoadingFiles(false);
  };
  /* const handleUploadToS3 = async (url, file, scan = false) => {
    let options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    await axios
      .put(url, file, options)
      .then((result) => {})
      .catch((error) => {
        setSnackbar({
          status: true,
          snackbarMessage: 'There was an error on the upload.',
          severity: 'error',
        });
        window.log(error);
      });
  }; */
  let uploadFiles = 0;
  const handleUploadToS3 = async (
    url: string,
    file: any,
    id: string,
    scan: boolean = false,
    totalFiles: number,
    taskId: string
  ) => {
    window.log('trying to upload');
    try {
      const retries = 3; // amount of retries we're willing to do
      for (let i = 0; i < retries; i++) {
        let options = {
          headers: {
            'Content-Type': file.type,
          },
        };
        // if (i === 0 || i === 1 || i === 2) options = {}; // generates an error for testing
        try {
          const req = await axios.put(url, file, options);
          window.log(req);
          if (req && req.status === 200) {
            uploadFiles = uploadFiles + 1;
            rtcContext.imgUploadSuccess({ id, taskId });
            if (selectedFolder?.ocrEnabled) {
              // If folder has ocr enabled, we immediately store its data into IDB to manage its "textract" key later
              let newStatus = {
                recordID: id,
                status: 'unindexed',
                fields: {},
                textract: false,
              };
              db.table('indexingScreen').add(newStatus);
            }
            break;
          } else {
            window.log('cannot fetch data');
          }
        } catch (error) {
          if (i === 2) {
            rtcContext.imgUploadFailed({ id, taskId });
            file.id = id;
            setErrorFiles((prevState) => [...prevState, file]);
          }
        }
      }
    } catch (e) {
      window.log(e);
    } finally {
      let totalRecords = errorFiles.length + uploadFiles;
      window.log(totalRecords);
      setSnackbar({
        status: true,
        snackbarMessage: `${uploadFiles} Files successfully uploaded`,
        severity: 'success',
      });
      if (selectedFolder?.ocrEnabled) {
        setTimeout(() => {
          setSnackbar({
            status: true,
            snackbarMessage: `${uploadFiles} Files are being OCRed`,
            severity: 'success',
          });
        }, 3000);
      }
    }
  };
  const handleCloseSnackbar = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: snackbar.severity,
    });
  };
  useEffect(() => {
    if (errorFiles.length === 0) return;
    handleOpen();
    let IDs = errorFiles.map((file: any) => file.id);
    deleteRecords(IDs, false);
  }, [errorFiles]);
  if (users) {
    return (
      <>
        {errorFiles && (
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Alert
              sx={alertStyles}
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  sx={{ margin: 0 }}
                  onClick={handleCloseModal}
                >
                  OK
                </Button>
              }
            >
              <AlertTitle sx={{ marginBottom: 2, fontWeight: 500, fontSize: 15 }}>
                Error uploading these files
              </AlertTitle>
              {errorFiles.map((error) => (
                <Typography key={error.name} sx={{ fontWeight: 500 }}>
                  - {error.name}
                </Typography>
              ))}
            </Alert>
          </Modal>
        )}
        <Dialog
          maxWidth={false}
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" sx={classes.title}>
                Document Scanner
              </Typography>
            </Toolbar>
          </AppBar>
          <DWT
            productKey={productKey}
            features={['scan', 'save', 'upload']}
            selectedFolder={selectedFolder}
            createRecord={createRecord}
            setUnindexed={setUnindexed}
            unIndexed={unIndexed}
            handleUploadToS3={handleUploadToS3}
            users={users}
            theUser={theUser}
            addTask={addTask}
            handleClose={handleClose}
            rtcContext={rtcContext}
          />
        </Dialog>
      </>
    );
  } else {
    return <Box></Box>;
  }
};

export default ScanningModal;
